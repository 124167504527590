import { useListUserPlayLists } from "@hooks/user/playList/useList";
import { TextField, TextFieldProps } from "@material-ui/core";
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
} from "@material-ui/lab";
import { PlayListDocument } from "@repositories/playList/types";
import React from "react";
import { Control, useController } from "react-hook-form";

interface Props {
  control: Control<any>;
  name: string;
  autocompleteProps?: Partial<AutocompleteProps<string, false, true, true>>;
  textFieldProps?: Partial<TextFieldProps>;
}

function PlayListSelectOrCreateField(props: Props) {
  const { control, name, autocompleteProps, textFieldProps } = props;

  const { field, formState } = useController<any>({ control, name });
  const { value, onChange } = field;
  const error = formState.errors[name];

  const {
    data: playLists,
    isLoading,
    setOptions,
  } = useListUserPlayLists<PlayListDocument>({
    populated: false,
    id: value,
  });

  const selectedValue = playLists.find((playList) => {
    return playList._id === value;
  });

  const getOptionLabel = (option: string | PlayListDocument) => {
    const isString = typeof option === "string";
    return isString ? option : option.name;
  };

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      variant="standard"
      {...textFieldProps}
      {...params}
      error={!!error}
      helperText={error}
    />
  );

  const onChangeValue = (_: React.ChangeEvent<{}>, value: PlayListDocument) => {
    const updatedId = value._id;
    setOptions((prev) => ({ ...prev, id: updatedId }));
    onChange(updatedId);
  };

  return (
    <React.Fragment>
      <Autocomplete
        {...autocompleteProps}
        freeSolo
        loading={isLoading}
        options={playLists}
        value={selectedValue}
        disableClearable
        getOptionLabel={getOptionLabel}
        renderInput={renderInput}
        onChange={onChangeValue}
      />
    </React.Fragment>
  );
}

export default PlayListSelectOrCreateField;
