import { Box, makeStyles, Typography } from "@material-ui/core";
import { Collection } from "@repositories/collection/types";
import React from "react";

const NEXT_PUBLIC_BASE_DOMAIN = process.env.NEXT_PUBLIC_BASE_DOMAIN;
const IMAGE_URL = `${NEXT_PUBLIC_BASE_DOMAIN}/api/v1/images/source`;

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    paddingTop: "56.25%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    position: "relative",
  },
}));

interface Props {
  cover: Collection["cover"];
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  hideGradient?: boolean;
}

export default function CollectionCover(props: Props): JSX.Element {
  const classes = useStyles();

  const { cover, hideGradient, title, subtitle } = props;

  const imageSrc = cover
    ? `${IMAGE_URL}?provider=${cover.provider}&reference=${cover.reference}`
    : "";

  const gradient = hideGradient
    ? ""
    : `linear-gradient(to bottom, rgba(255, 108, 0, 0.25), rgba(255, 108, 0, 0.52)),`;

  return (
    <Box
      className={classes.root}
      style={{
        backgroundImage: `${gradient} url(${imageSrc})`,
        transition: "height 0.5s",
      }}
    >
      <Box position="absolute" bottom={16} left={16}>
        <Typography variant="h3">{title}</Typography>
        <Typography variant="h5">{subtitle}</Typography>
      </Box>
    </Box>
  );
}
