import CollectionGridItem from "@components/collection/gridItem";
import CollectionGridHeader from "@components/collection/rowHeader";
import GridWrapper from "@components/elements/GridWrapper";
import { CollectionPopulatedDocument } from "@repositories/collection/types";
import React from "react";

interface Props {
  itemSizes?: {
    [size: string]: number;
  };
  collectionList: CollectionPopulatedDocument[];
}

export default function CollectionGrid(props: Props): JSX.Element {
  const { itemSizes = { xs: 12, sm: 6, md: 4 }, collectionList } = props;

  const listNodes = collectionList.map((collection) => {
    return collection && <CollectionGridItem collection={collection} />;
  });

  return (
    <GridWrapper itemSizes={itemSizes} spacing={2}>
      {React.Children.toArray(listNodes)}
    </GridWrapper>
  );
}
