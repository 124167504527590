import CollectionListWithData from "@components/collection/gridWithData";
import GridWrapper from "@components/elements/GridWrapper";
import PlayListAttachVideoDialog from "@components/playLists/attachVideoDialog";
import UserResourceRateButton from "@components/userResource/rateButton";
import UserResourceWatchLaterButton from "@components/userResource/watchLaterButton";
import VideoCover from "@components/video/cover";
import VideoRelatedRow from "@components/video/relatedRow";
import DialogWrapper, { DialogWrapperRef } from "@components/wrappers/dialog";
import { useAuth } from "@hooks/auth/useAuth";
import {
  Box,
  Button,
  CardActions,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { USER_RESOURCE_VIDEO } from "@repositories/userResource/types";
import { VideoPopulatedDocument } from "@repositories/video/types";
import { authUser } from "@services/auth/authUser";
import React from "react";

interface Ref {
  open: () => void;
}

interface Props {
  video: VideoPopulatedDocument;
  buttonText?: string;
  isGuest?: boolean;
}

function render(props: Props, ref: React.RefObject<Ref>): JSX.Element {
  const { video, buttonText, isGuest } = props;

  const theme = useTheme();

  const { isAuth } = useAuth();

  const videoId = video._id;
  const videoName = video.name;
  const videoDescription = video.description;
  const videoDuration = video.duration;
  const videoCreatedAt = video.createdAt;
  const videoCreator = video.creator;
  const videoCreatorName = videoCreator.name;
  const videoCreatorAvatar = videoCreator.avatar;
  const videoCategories = video.categories;
  const videoTags = video.tags;
  const videoCollections = video.collections;
  const collectionIds = videoCollections.map((collection) => collection._id);

  const dialogRef = React.useRef<DialogWrapperRef>();

  const onOpen = () => {
    dialogRef.current.open();
  };

  const onClose = () => {
    dialogRef.current.close();
  };

  React.useImperativeHandle(ref, () => ({
    open: onOpen,
  }));

  return (
    <DialogWrapper
      ref={dialogRef}
      buttonText={buttonText}
      dialogProps={{ maxWidth: "md" }}
      removeDialogTitle
      dialogTitle={
        <DialogTitle
          style={{
            position: "absolute",
            background: "transparent",
            width: "100%",
            zIndex: theme.zIndex.modal + 1,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            color="primary"
            style={{ position: "fixed", marginLeft: "-48px" }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      }
      removeDialogContent
    >
      <VideoCover video={video} />
      <Box p={2}>
        <GridWrapper spacing={2}>
          <Box p={2}>
            <Typography variant="h4">{videoName}</Typography>
            <Typography variant="body1">{videoDescription}</Typography>
          </Box>
          <Box display="flex" mx={2}>
            <Box mr={8}>
              <Typography variant="overline" color="textSecondary">
                Categories
              </Typography>
              <Typography
                variant="body1"
                style={{ textTransform: "capitalize" }}
              >
                {videoCategories.map((item) => item.name).join(", ")}
              </Typography>
            </Box>
            <Box mr={8}>
              <Typography variant="overline" color="textSecondary">
                Tags
              </Typography>
              <Typography
                variant="body1"
                style={{ textTransform: "capitalize" }}
              >
                {videoTags.map((item) => item.name).join(", ")}
              </Typography>
            </Box>
          </Box>
          {isAuth && (
            <Box p={2}>
              <CardActions style={{ justifyContent: "flex-end" }}>
                <UserResourceWatchLaterButton
                  resourceId={videoId}
                  resourceType={USER_RESOURCE_VIDEO}
                />
                <UserResourceRateButton
                  resourceId={videoId}
                  resourceType={USER_RESOURCE_VIDEO}
                />

                <PlayListAttachVideoDialog videoId={videoId} />

                <IconButton style={{ borderRadius: 5 }}>
                  <Tooltip title="Options" aria-label="options">
                    <MoreHorizIcon />
                  </Tooltip>
                </IconButton>
              </CardActions>
              <Divider />
            </Box>
          )}

          <CollectionListWithData
            title="Collections"
            queryOptions={{ collections: collectionIds }}
          />

          <VideoRelatedRow
            title="Others like this"
            slidesToShow={3}
            videoId={videoId}
          />
        </GridWrapper>
      </Box>
    </DialogWrapper>
  );
}

const VideoDialog = React.forwardRef(render);

export default VideoDialog;
export interface VideoDialogRef extends Ref {}
