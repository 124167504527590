import { Box, Slide, useScrollTrigger } from "@material-ui/core";
import React from "react";

interface Props {
  children: JSX.Element;
}

export default function HideOnScroll(props: Props): JSX.Element {
  const { children } = props;
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
