import GridWrapper from "@components/elements/GridWrapper";
import { useAuth } from "@hooks/auth/useAuth";
import { Box, Button, IconButton, Typography } from "@material-ui/core";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { makeStyles } from "@material-ui/styles";
import { VideoPopulatedDocument } from "@repositories/video/types";
import { retrieveVideoSourceToken } from "@requests/videos/token";
import { triggerMinuteUserResource } from "@utilities/web/triggerMinuteUserResource";
import React from "react";
import ReactPlayer from "react-player";
import { AutoSizer } from "react-virtualized";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";

interface VideoCoverOptions {
  coverImageUrl: string;
}

const useStyles = makeStyles(() => ({
  coverImage: {
    height: 0,
    paddingTop: "56.25%",
    // backgroundImage: (options: VideoCoverOptions) =>
    //   `linear-gradient(-225deg, rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url(${options.coverImageUrl})`,
    backgroundImage: (options: VideoCoverOptions) =>
      `url(${options.coverImageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  coverGradient: {
    position: "absolute",
    top: 0 * 8,
    display: "flex",
    alignItems: "center",
    // background: `linear-gradient(0deg, rgba(35,43,50,1) 5%, rgba(116,132,131,0) 45%, rgba(123,140,138,0) 50%)`,
    // background: `linear-gradient(0deg, rgba(255,255,255,1) 5%, rgba(255,255,255,0) 25%, rgba(255,255,255,0) 50%)`,
  },
  coverContent: {
    // paddingLeft: 4,
    // paddingRight: 4,
    padding: 8,
    position: "absolute",
    top: 0 * 8,
    display: "flex",
    alignItems: "center",
  },
}));

interface Props {
  video: VideoPopulatedDocument;
}

export default function VideoCover(props: Props): JSX.Element {
  const { video } = props;
  const videoId = video._id;
  const videoName = video.name;
  const videoDescription = video.description;

  const { isAuth } = useAuth();

  const [token, setToken] = React.useState(null);

  const thumbUrl = `/api/v1/videos/${videoId}/thumb`;
  const videoUrl = `https://videodelivery.net/${token}/manifest/video.mpd`;

  const classes = useStyles({
    coverImageUrl: thumbUrl,
  });

  const videoPlayerRef = React.createRef<ReactPlayer>();
  const [started, setStart] = React.useState(false);
  const [playing, setPlay] = React.useState(false);

  const onStart = () => {
    setStart(true);
    setPlay(true);
  };

  const [duration, setDuration] = React.useState(0);
  const [progress, setProgress] = React.useState(0);

  const roundedDuration = Math.floor(duration / 10) * 10;

  const updateUserResource = (playedSeconds: number) => {
    setProgress(playedSeconds);
    const hasFinished = playedSeconds >= roundedDuration;
    triggerMinuteUserResource(videoId, playedSeconds, hasFinished);

    return hasFinished ? setProgress(0) : null;
  };

  const onProgress = (pState: {
    played: number;
    playedSeconds: number;
    loaded: number;
    loadedSeconds: number;
  }) => {
    const playedSeconds = pState.playedSeconds;
    const deltaSeconds = pState.playedSeconds - progress;
    const tenSeconds = deltaSeconds >= 10;
    const hasFinished = pState.playedSeconds >= roundedDuration;

    // console.log({
    //   progress,
    //   deltaSeconds,
    //   tenSeconds,
    //   hasFinished,
    //   playedSeconds,
    // });

    return tenSeconds || hasFinished
      ? updateUserResource(pState.playedSeconds)
      : null;
  };

  const retrieveToken = () => {
    return isAuth
      ? retrieveVideoSourceToken(videoId).then(setToken).catch(console.error)
      : null;
  };

  React.useEffect(() => {
    retrieveToken();
  }, []);

  return (
    <Box>
      <Box className={classes.coverImage} />
      <AutoSizer>
        {({ width, height }) => (
          <React.Fragment>
            <Box
              width={width}
              height={height}
              className={classes.coverGradient}
            />
            <Box width={width} height={height} className={classes.coverContent}>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* <Typography variant="h4">{videoName}</Typography>
                  <Typography variant="body1">{videoDescription}</Typography> */}
                {isAuth && (
                  <IconButton onClick={onStart} disabled={!token}>
                    <img
                      src="/assets/images/play.svg"
                      height={200}
                      width={200}
                    />
                  </IconButton>
                  // <Button
                  //   variant="contained"
                  //   color="primary"
                  //   startIcon={<PlayArrowIcon />}
                  // >
                  //   Play
                  // </Button>
                )}
              </Box>
            </Box>
            {started && (
              <ReactPlayer
                ref={videoPlayerRef}
                playing={playing}
                url={videoUrl}
                controls
                width="100%"
                height="100%"
                style={{ position: "absolute", top: 0, left: 0 }}
                onProgress={onProgress}
                onDuration={setDuration}
              />
            )}
          </React.Fragment>
        )}
      </AutoSizer>
    </Box>
  );
}
