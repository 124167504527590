export type VideoStatus =
  | "New"
  | "Uploading"
  | "Encoding"
  | "Error"
  | "Ready"
  | "Published"
  | "Suspended";

export const VIDEO_STATUS_NEW: VideoStatus = "New";
export const VIDEO_STATUS_UPLOADING: VideoStatus = "Uploading";
export const VIDEO_STATUS_ENCODING: VideoStatus = "Encoding";
export const VIDEO_STATUS_ERROR: VideoStatus = "Error";
export const VIDEO_STATUS_READY: VideoStatus = "Ready";
export const VIDEO_STATUS_PUBLISHED: VideoStatus = "Published";
export const VIDEO_STATUS_SUSENDED: VideoStatus = "Suspended";

export const VideoStatuses: VideoStatus[] = [
  VIDEO_STATUS_NEW,
  VIDEO_STATUS_UPLOADING,
  VIDEO_STATUS_ENCODING,
  VIDEO_STATUS_ERROR,
  VIDEO_STATUS_READY,
  VIDEO_STATUS_PUBLISHED,
  VIDEO_STATUS_SUSENDED,
];

export const ThumbableVideoStatuses: VideoStatus[] = [
  VIDEO_STATUS_READY,
  VIDEO_STATUS_PUBLISHED,
  VIDEO_STATUS_SUSENDED,
];

export const PlayableVideoStatuses: VideoStatus[] = [
  VIDEO_STATUS_READY,
  VIDEO_STATUS_PUBLISHED,
  VIDEO_STATUS_SUSENDED,
];

export const UploadableVideoStatuses: VideoStatus[] = [
  VIDEO_STATUS_NEW,
  VIDEO_STATUS_READY,
  VIDEO_STATUS_PUBLISHED,
];

export const WarningVideoStatuses: VideoStatus[] = [
  VIDEO_STATUS_UPLOADING,
  VIDEO_STATUS_ENCODING,
];

export const ErrorVideoStatuses: VideoStatus[] = [
  VIDEO_STATUS_ERROR,
  VIDEO_STATUS_SUSENDED,
];

export const ValidVideoStatuses: VideoStatus[] = [
  VIDEO_STATUS_READY,
  VIDEO_STATUS_PUBLISHED,
];
