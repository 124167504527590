import {
  UserResource,
  UserResourceMeta,
} from "@repositories/userResource/types";
import { createUserResource } from "@requests/userResources/create";

export const triggerRateUserResource = (
  resourceId: string,
  resourceType: string,
  rate: boolean,
) => {
  const like: UserResourceMeta = { like: true, dislike: false };
  const dislike: UserResourceMeta = { dislike: true, like: false };

  const rateMeta = rate ? like : dislike;

  const doc: UserResource = {
    type: resourceType,
    resource: resourceId,
    meta: rateMeta,
  };

  return createUserResource(doc);
};
