import axios from "axios";
import { QueryOptions } from "./queryParser.types";

export const queryFetcher = async (url: string, options?: QueryOptions) => {
  const response = await axios.get(url, { params: options });

  const data = response.data;

  return data;
};

export const queryFetcherWithAuth = (accessToken: string) => {
  return async (url: string, options?: QueryOptions) => {
    const response = await axios.get(url, {
      params: options,
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    const data = response.data;

    return data;
  };
};
