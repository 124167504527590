import { queryFetcher } from "@utilities/helpers/queryFetcher";
import { QueryOptions } from "@utilities/helpers/queryParser.types";
import React from "react";
import useSWR from "swr";

export function useListCollection<T>(defaultOptions: QueryOptions) {
  const endpoint = "/api/v1/collections";

  const [options, setOptions] = React.useState<QueryOptions>({
    size: 6,
    populated: true,
    ...defaultOptions,
  });

  const { data, isValidating, revalidate } = useSWR(
    [endpoint, options],
    queryFetcher,
  );

  const result = data?.data as T[];

  return {
    data: result || [],
    count: data?.count || 0,
    error: data?.error || null,
    isLoading: isValidating,
    reload: revalidate,
    options,
    setOptions,
  };
}
