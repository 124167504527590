import Slider from "@components/elements/slider";
import VideoRowHeader from "@components/video/rowHeader";
import VideoRowItem from "@components/video/rowItem";
import { VIDEO_STATUS_READY } from "@constants/videos";
import { useListVideo } from "@hooks/video/useList";
import { useRelatedVideo } from "@hooks/video/useRelated";
import { Box } from "@material-ui/core";
import { VideoPopulatedDocument } from "@repositories/video/types";
import { QueryOptions } from "@utilities/helpers/queryParser.types";
import React from "react";
import { Settings } from "react-slick";

interface Props {
  title?: string;
  slidesToShow?: number;
  videoId: string;
}

export default function VideoRelatedRow(props: Props): JSX.Element {
  const { title, videoId } = props;

  const defaultOptions: QueryOptions = {
    size: 10,
    populated: true,
    status: VIDEO_STATUS_READY,
  };
  const [options] = React.useState<QueryOptions>(defaultOptions);
  const { data: videoList, count } = useRelatedVideo<VideoPopulatedDocument>(
    videoId,
    options,
  );

  const hasVideos = videoList.length > 0;

  const sliderSettings: Settings = {
    dots: false,
    slidesToScroll: 1,
    slidesToShow: 4,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: count <= 4 ? count : 4,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: count <= 4 ? count : 4,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: count <= 3 ? count : 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: count <= 2 ? count : 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: count <= 1 ? count : 1,
        },
      },
    ],
  };

  const listNodes = videoList.map((video) => (
    <Box p={0.5} height="100%">
      <VideoRowItem video={video} />
    </Box>
  ));

  return (
    hasVideos && (
      <Box>
        <VideoRowHeader title={title} caption="Videos" />
        <Box px={2}>
          <Slider settings={sliderSettings}>
            {React.Children.toArray(listNodes)}
          </Slider>
        </Box>
      </Box>
    )
  );
}
