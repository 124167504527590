import MainSectionHeader from "@components/layouts/main/sections/header";
import { Box, Typography } from "@material-ui/core";
import React from "react";

interface Props {
  caption?: string;
  title?: string;
  actions?: React.ReactNode;
}

export default function VideoRowHeader(props: Props): JSX.Element {
  const { caption, title, actions } = props;
  return (
    <MainSectionHeader caption={caption} title={title} actions={actions} />
  );
}
