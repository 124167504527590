import { queryFetcher } from "@utilities/helpers/queryFetcher";
import { QueryOptions } from "@utilities/helpers/queryParser.types";
import useSWR from "swr";

export function useRelatedVideo<T>(videoId: string, options: QueryOptions) {
  const endpoint = `/api/v1/videos/${videoId}/related`;

  const { data, isValidating, revalidate } = useSWR(
    [endpoint, options],
    queryFetcher,
  );

  const result = data?.data as T[];

  return {
    data: result || [],
    count: data?.count || 0,
    error: data?.error || null,
    isLoading: isValidating,
    reload: revalidate,
  };
}
