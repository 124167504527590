import {
  UserResource,
  UserResourceDocument,
} from "@repositories/userResource/types";
import axios from "axios";

export async function minuteUserResource(doc: UserResource) {
  const endpoint = `/api/v1/user/resources/video/minute`;
  const response = await axios.put(endpoint, doc);

  const responseData = response.data;
  return responseData.data as UserResourceDocument;
}
