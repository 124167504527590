import CollectionCover from "@components/collection/cover";
import CollectionDialog, {
  CollectionDialogRef,
} from "@components/collection/dialog";
import {
  Box,
  Card,
  CardActionArea,
  alpha,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ViewListIcon from "@material-ui/icons/ViewList";
import { CollectionPopulatedDocument } from "@repositories/collection/types";
import React from "react";
import { AutoSizer } from "react-virtualized";

const useStyles = makeStyles((theme) => ({
  content: {
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundSize: "cover",
  },
  overlay: {
    backgroundColor: alpha(theme.palette.primary.main, 0.75),
    position: "absolute",
    top: 0,
    right: 0,
    padding: 8,
    display: "flex",
    height: "100%",
    width: "30%",
    alignItems: "center",
  },
}));

interface Props {
  collection: CollectionPopulatedDocument;
}

export default function CollectionGridItem(props: Props): JSX.Element {
  const { collection } = props;
  const collectionId = collection._id;
  const collectionCover = collection.cover;
  const collectionName = collection.name;
  const collectionDescription = collection.description;
  const collectionVideos = collection.videos || [];

  const classes = useStyles();

  const collectionDialogRef = React.useRef<CollectionDialogRef>();

  const onOpen = () => {
    collectionDialogRef.current.open();
  };

  return (
    <Card
      variant="outlined"
      style={{
        borderRadius: 8,
        overflow: "hidden",
      }}
    >
      <CardActionArea
        onClick={onOpen}
        style={{
          flex: 1,
          display: "flex",
          position: "relative",
        }}
      >
        <CollectionCover cover={collectionCover} />
        <AutoSizer>
          {({ width, height }) => (
            <Box
              className={classes.content}
              style={{ height, width: width * 0.7 }}
            >
              <Box textAlign="left" pl={1}>
                <Typography variant="h5">{collectionName}</Typography>
                <Typography variant="subtitle1" component="p">
                  {collectionDescription}
                </Typography>
              </Box>
            </Box>
          )}
        </AutoSizer>
        <Box className={classes.overlay}>
          <ViewListIcon style={{ display: "inline-block" }} /> List
        </Box>
      </CardActionArea>
      <CollectionDialog collectionId={collectionId} ref={collectionDialogRef} />
    </Card>
  );
}
