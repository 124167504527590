import GridWrapper from "@components/elements/GridWrapper";
import {
  Box,
  Container,
  Divider,
  MenuItem,
  MenuList,
  Typography,
} from "@material-ui/core";
import React from "react";

export default function MainFooter(): JSX.Element {
  return (
    <Box minHeight="15vh">
      <Divider style={{ height: 4, marginBottom: 24 }} />
      <Container>
        <GridWrapper itemSizes={{ xs: 4 }}>
          <Box>
            <img src="/assets/logos/bgf_logo.png" width="100%" />
          </Box>
          <Box>
            <MenuList>
              <MenuItem>FAQ</MenuItem>
              <MenuItem>Terms of Use</MenuItem>
              <MenuItem>Contact Us</MenuItem>
            </MenuList>
          </Box>
          <Box>
            <MenuList>
              <MenuItem>Account</MenuItem>
              <MenuItem>Privacy</MenuItem>
              <MenuItem>About Us</MenuItem>
            </MenuList>
          </Box>
        </GridWrapper>
        <Box p={2}>
          <Typography color="textSecondary">
            &#169; 2021 Best Golf Fitness
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
