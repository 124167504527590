import CollectionGrid from "@components/collection/grid";
import CollectionGridHeader from "@components/collection/rowHeader";
import GridWrapper from "@components/elements/GridWrapper";
import ListPaginationWrapper from "@components/wrappers/list/pagination";
import { useListCollection } from "@hooks/collection/useList";
import { CollectionPopulatedDocument } from "@repositories/collection/types";
import { QueryOptions } from "@utilities/helpers/queryParser.types";
import React from "react";

interface Props {
  title?: string;
  itemSizes?: {
    [size: string]: number;
  };
  queryOptions?: QueryOptions;
}

export default function CollectionGridWithData(props: Props): JSX.Element {
  const { title, itemSizes = { xs: 12, sm: 6, md: 4 }, queryOptions } = props;

  const {
    data: collectionList,
    count,
    options: { page, size },
    setOptions,
  } = useListCollection<CollectionPopulatedDocument>(queryOptions);

  const handleRefresh = (page: number, size: number) => {
    setOptions((prev) => ({ ...prev, page, size }));
  };

  return (
    <GridWrapper spacing={2}>
      <CollectionGridHeader title={title} caption="Collections" />
      <CollectionGrid itemSizes={itemSizes} collectionList={collectionList} />
      <ListPaginationWrapper
        page={page}
        size={size}
        count={count}
        onChange={handleRefresh}
      />
    </GridWrapper>
  );
}
