import { Button, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import Link from "@material-ui/core/Link";
import React from "react";

const useStyles = makeStyles((theme) => ({
  navButton: {
    [theme.breakpoints.up("md")]: {
      textTransform: "none",
      marginRight: theme.spacing(2),
    },

    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
      width: "100%",
    },
  },
  pushLeft: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "auto",
    },
    [theme.breakpoints.down("md")]: {},
  },
  loginButton: { color: theme.palette.common.white },
}));

export default function MemberNav(): JSX.Element {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Link href="/browse">
        <Button color="inherit" className={classes.navButton}>
          Browse
        </Button>
      </Link>
      <Link href="/coaches">
        <Button color="inherit" className={classes.navButton}>
          Coaches
        </Button>
      </Link>
      <Link href="/workouts">
        <Button color="inherit" className={classes.navButton}>
          Workouts
        </Button>
      </Link>

      <Link href="/profile">
        <Button
          color="secondary"
          className={classNames([classes.navButton, classes.pushLeft])}
        >
          Profile
        </Button>
      </Link>
    </React.Fragment>
  );
}
