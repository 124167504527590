import { TablePagination } from "@material-ui/core";
import React from "react";

interface Props {
  page?: number;
  size?: number;
  count?: number;
  onChange?: (page: number, size: number) => void;
  sizePerPageLabel?: string;
}

function ListPaginationWrapper(props: Props) {
  const {
    page = 0,
    count = 10,
    size = 10,
    sizePerPageLabel = "Per page:",
  } = props;

  const handleChangePage = (_: any, newPage: number) => {
    return props.onChange ? props.onChange(newPage, size) : null;
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<{ value: any }>,
  ) => {
    const newSize = event.target.value;
    console.log({ newSize });
    return props.onChange ? props.onChange(page, newSize) : null;
  };

  return (
    <TablePagination
      component="div"
      count={count}
      page={page}
      rowsPerPage={size}
      labelRowsPerPage={sizePerPageLabel}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={[10, 20, 50]}
    />
  );
}

export default ListPaginationWrapper;
