import { Button } from "@material-ui/core";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import PlaylistPlayIcon from "@material-ui/icons/PlaylistPlay";
import { UserResourceDocument } from "@repositories/userResource/types";
import { retrieveUserResource } from "@requests/userResources/retrieve";
import { triggerRateUserResource } from "@utilities/web/triggerRateUserResource";
import { triggerWatchLaterUserResource } from "@utilities/web/triggerWatchLaterUserResource";
import React from "react";

interface Props {
  resourceId: string;
  resourceType: string;
  userResource?: UserResourceDocument;
  onUserResource?: (userResource: UserResourceDocument) => void;
}

export default function UserResourceWatchLaterButton(
  props: Props,
): JSX.Element {
  const { resourceId, resourceType } = props;

  const [userResource, setUserResource] = React.useState<UserResourceDocument>(
    props.userResource,
  );

  const watchLater = userResource?.meta?.watchLater || false;
  const icon = watchLater ? <PlaylistPlayIcon /> : <PlaylistAddIcon />;
  const color = watchLater ? "primary" : "default";

  const onUserResource = (updatedUserResource: UserResourceDocument) => {
    return props.onUserResource
      ? props.onUserResource(updatedUserResource)
      : null;
  };

  const onClick = () => {
    triggerWatchLaterUserResource(resourceId, resourceType, !watchLater).then(
      setUserResource,
    );
  };

  const getUserResource = () => {
    retrieveUserResource({ resource: resourceId, type: resourceType }).then(
      setUserResource,
    );
  };

  React.useEffect(() => {
    getUserResource();
  }, []);

  React.useEffect(() => {
    onUserResource(userResource);
  }, [userResource]);

  return (
    <Button startIcon={icon} onClick={onClick} color={color}>
      {watchLater ? "Saved for Later" : "Watch Later"}
    </Button>
  );
}
