import { PlayList, PlayListDocument } from "@repositories/playList/types";
import axios from "axios";

export async function attachPlayListVideo(videoId: string, playListId: string) {
  const endpoint = `/api/v1/user/play-lists/${playListId}/videos/${videoId}`;
  const response = await axios.patch(endpoint, { attach: true });

  const responseData = response.data;
  return responseData.data as PlayListDocument;
}
