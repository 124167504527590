import { PlayList, PlayListDocument } from "@repositories/playList/types";
import { createRequest } from "@utilities/helpers/createRequest";
import { queryFetcher } from "@utilities/helpers/queryFetcher";
import { QueryOptions } from "@utilities/helpers/queryParser.types";
import React from "react";
import useSWR from "swr";

export function useListUserPlayLists<R>(defaultOptions?: QueryOptions) {
  const endpoint = `/api/v1/user/play-lists`;

  const [options, setOptions] = React.useState<QueryOptions>({
    size: 10,
    populated: true,
    ...defaultOptions,
  });

  const { data, revalidate, mutate } = useSWR(
    [endpoint, options],
    queryFetcher,
  );

  const createMutate = async (doc: PlayList) => {
    const create = createRequest<PlayList, PlayListDocument>(doc);
    const result = await create(endpoint);
    return mutate(result, true);
  };

  const result = data?.data as R[];

  return {
    data: result || [],
    count: data?.count || 0,
    error: data?.error || null,
    isLoading: !data?.data && !data?.error,
    reload: revalidate,
    create: createMutate,
    options,
    setOptions,
  };
}
