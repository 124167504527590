import { Avatar } from "@material-ui/core";
import { UserProfileDocument } from "@repositories/userProfile/types";
import React from "react";

const NEXT_PUBLIC_BASE_DOMAIN = process.env.NEXT_PUBLIC_BASE_DOMAIN;
const IMAGE_URL = `${NEXT_PUBLIC_BASE_DOMAIN}/api/v1/images/source`;

interface Props {
  avatar: UserProfileDocument["avatar"];
}

export default function UserProfileAvatar(props: Props): JSX.Element {
  const { avatar } = props;

  const { provider, reference } = avatar;

  const imageSrc = `${IMAGE_URL}?provider=${provider}&reference=${reference}`;

  return <Avatar variant="rounded" src={imageSrc} />;
}
