import { Box, Button } from "@material-ui/core";
import React from "react";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import { UserResourceDocument } from "@repositories/userResource/types";
import { triggerFollowUserResource } from "@utilities/web/triggerFollowUserResource";

interface Props {
  resourceId: string;
  resourceType: string;
  userResource?: UserResourceDocument;
  onUserResource?: (userResource: UserResourceDocument) => void;
}

export default function UserResourceFollowButton(props: Props): JSX.Element {
  const { resourceId, resourceType } = props;

  const [userResource, setUserResource] = React.useState<UserResourceDocument>(
    props.userResource,
  );

  const follow = !!userResource?.meta.follow;
  const icon = follow ? <BookmarkIcon /> : <BookmarkBorderIcon />;
  const color = follow ? "primary" : "default";

  const onUserResource = (updatedUserResource: UserResourceDocument) => {
    return props.onUserResource
      ? props.onUserResource(updatedUserResource)
      : null;
  };

  const onClick = () => {
    triggerFollowUserResource(resourceId, resourceType, !follow).then(
      setUserResource,
    );
  };

  React.useEffect(() => {
    onUserResource(userResource);
  }, [userResource]);

  return (
    <Button startIcon={icon} onClick={onClick} color={color}>
      {follow ? "Followed" : "Follow"}
    </Button>
  );
}
