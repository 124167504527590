import { UserDocument } from "@repositories/user/types";
import { Document, Model, Types } from "mongoose";

// Interfaces
type UserType = string | Types.ObjectId | UserDocument;
type ResourceType = string | Types.ObjectId | Document;

// export interface UserResourceMetaRatable {
//   liked?: boolean;
//   disliked?: boolean;
// }

// export interface UserResourceMetaFollowable {
//   follow?: boolean;
// }

// export interface UserResourceMetaVideo extends UserResourceMetaRatable {
//   stoppedAt?: number;
//   started?: boolean; // Auto Generated
//   finished?: boolean; // Auto Generated
//   playedFor?: number; // Auto Generated
// }
// export interface UserResourceMetaCollection
//   extends UserResourceMetaRatable,
//     UserResourceMetaFollowable {}
// export interface UserResourceMetaCoach
//   extends UserResourceMetaRatable,
//     UserResourceMetaFollowable {}
// export interface UserResourceMetaTag
//   extends UserResourceMetaRatable,
//     UserResourceMetaFollowable {}
// export interface UserResourceMetaCategory
//   extends UserResourceMetaRatable,
//     UserResourceMetaFollowable {}

// export type UserResourceMeta =
//   | UserResourceMetaVideo
//   | UserResourceMetaCollection
//   | UserResourceMetaCoach
//   | UserResourceMetaTag
//   | UserResourceMetaCategory;

export type UserResourceType =
  | "video"
  | "collection"
  | "coach"
  | "tag"
  | "category";

export const USER_RESOURCE_VIDEO: UserResourceType = "video";
export const USER_RESOURCE_COLLECTION: UserResourceType = "collection";
export const USER_RESOURCE_COACH: UserResourceType = "coach";
export const USER_RESOURCE_TAG: UserResourceType = "tag";
export const USER_RESOURCE_CATEGORY: UserResourceType = "category";

export type UserResourceCollection =
  | "like"
  | "dislike"
  | "follow"
  | "watchLater";

export const USER_RESOURCE_LIKE: UserResourceCollection = "like";
export const USER_RESOURCE_DISLIKE: UserResourceCollection = "dislike";
export const USER_RESOURCE_FOLLOW: UserResourceCollection = "follow";
export const USER_RESOURCE_WATCH_LATER: UserResourceCollection = "watchLater";

export interface UserResourceMeta {
  // Video
  stoppedAt?: number;
  finished?: boolean;
  started?: boolean; // Auto Generated
  playedFor?: number; // Auto Generated
  watchLater?: boolean;

  // Ratable
  like?: boolean;
  dislike?: boolean;

  // Followable
  follow?: boolean;
}

export interface UserResource {
  user?: UserType;
  resource: ResourceType;
  type: string;
  meta?: UserResourceMeta;
  createdAt?: Date;
  updatedAt?: Date;
}

// Documents
export interface UserResourceBaseDocument extends UserResource, Document {}
export interface UserResourceDocument extends UserResourceBaseDocument {}
export interface UserResourcePopulatedDocument
  extends UserResourceBaseDocument {
  user: UserDocument;
  resource: Document;
}

export type UserResourceDocuments =
  | UserResourceDocument
  | UserResourcePopulatedDocument;

// Model
export interface UserResourceModel extends Model<UserResourceDocument> {}
