import { queryFetcher } from "@utilities/helpers/queryFetcher";
import { QueryOptions } from "@utilities/helpers/queryParser.types";
import useSWR from "swr";

export function useRetrieveCollection<T>(
  collectionId: string,
  options: QueryOptions,
) {
  const endpoint = `/api/v1/collections/${collectionId}`;
  const { data, isValidating, revalidate, mutate } = useSWR(
    [endpoint, options],
    queryFetcher,
  );

  const result = data?.data as T;

  return {
    data: result || null,
    error: data?.error || null,
    isLoading: isValidating,
    reload: revalidate,
  };
}
