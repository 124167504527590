import { Box, Button, CardActions, Typography } from "@material-ui/core";
import React from "react";

interface Props {
  caption: string;
  title: string;
  actions?: React.ReactNode;
}

export default function MainSectionHeader(props: Props): JSX.Element {
  const { caption, title, actions } = props;

  return (
    <Box display="flex" pt={2}>
      <Box>
        <Typography variant="caption" color="primary">
          <strong>{caption}</strong>
        </Typography>
        <Typography variant="h5">
          <strong>{title}</strong>
        </Typography>
      </Box>
      <CardActions style={{ marginLeft: "auto" }}>{actions}</CardActions>
    </Box>
  );
}
