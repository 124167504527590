import { UserResource } from "@repositories/userResource/types";
import { createUserResource } from "@requests/userResources/create";

export const triggerFollowUserResource = (
  resourceId: string,
  resourceType: string,
  follow: boolean,
) => {
  const doc: UserResource = {
    type: resourceType,
    resource: resourceId,
    meta: { follow },
  };

  return createUserResource(doc);
};
