import CollectionCover from "@components/collection/cover";
import GridWrapper from "@components/elements/GridWrapper";
import UserResourceFollowButton from "@components/userResource/followButton";
import UserResourceRateButton from "@components/userResource/rateButton";
import UserResourceWatchLaterButton from "@components/userResource/watchLaterButton";
import VideoList from "@components/video/list";
import { useAuth } from "@hooks/auth/useAuth";
import { useRetrieveCollection } from "@hooks/collection/useRetrieve";
import { Box, CardActions, Divider, Typography } from "@material-ui/core";
import { CollectionPopulatedDocument } from "@repositories/collection/types";
import { QueryOptions } from "@utilities/helpers/queryParser.types";
import React from "react";

interface Props {
  collectionId: string;
}

export default function CollectionDetail(props: Props): JSX.Element {
  const { collectionId } = props;

  const { isAuth } = useAuth();

  const defaultOptions: QueryOptions = { populated: true };
  const [options] = React.useState<QueryOptions>(defaultOptions);
  const { data: collection } =
    useRetrieveCollection<CollectionPopulatedDocument>(collectionId, options);

  const collectionName = collection?.name;
  const collectionDescription = collection?.description;
  const collectionCover = collection?.cover;
  const collectionCreator = collection?.creator;
  const creatorName = collectionCreator?.name;
  const collectionTags = collection?.tags || [];

  return (
    <React.Fragment>
      <CollectionCover
        cover={collectionCover}
        title={collectionName}
        subtitle={collectionDescription}
      />

      <Box p={2}>
        <GridWrapper spacing={2}>
          {isAuth && (
            <CardActions style={{}}>
              <UserResourceRateButton
                resourceId={collectionId}
                resourceType={"collection"}
              />
              <UserResourceFollowButton
                resourceId={collectionId}
                resourceType={"collection"}
              />
              <UserResourceWatchLaterButton
                resourceId={collectionId}
                resourceType={"collection"}
              />
            </CardActions>
          )}
          <Divider />
          <Box>
            <Typography variant="subtitle2">{creatorName}</Typography>
            <Typography variant="caption">Creator</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">
              {collectionTags.map((tag) => tag.name).join(", ")}
            </Typography>
          </Box>
        </GridWrapper>
        <VideoList collectionId={collectionId} />
      </Box>
    </React.Fragment>
  );
}
