import {
  UserResource,
  UserResourceDocument,
} from "@repositories/userResource/types";
import axios from "axios";

export async function retrieveUserResource(doc: UserResource) {
  const endpoint = `/api/v1/user/resource`;
  const response = await axios.get(endpoint);

  const responseData = response.data;
  return responseData.data as UserResourceDocument;
}
