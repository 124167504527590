import CollectionDetail from "@components/collection/details";
import DialogWrapper, { DialogWrapperRef } from "@components/wrappers/dialog";
import { DialogTitle, IconButton, useTheme } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

interface Ref {
  open: () => void;
}

interface Props {
  collectionId: string;
}

function render(props: Props, ref: React.RefObject<Ref>): JSX.Element {
  const { collectionId } = props;

  const theme = useTheme();
  const dialogRef = React.useRef<DialogWrapperRef>();

  const onOpen = () => {
    dialogRef.current.open();
  };

  const onClose = () => {
    dialogRef.current.close();
  };

  React.useImperativeHandle(ref, () => ({
    open: onOpen,
  }));

  return (
    <DialogWrapper
      ref={dialogRef}
      removeDialogTitle
      removeDialogContent
      dialogTitle={
        <DialogTitle
          style={{
            position: "absolute",
            background: "transparent",
            width: "100%",
            zIndex: theme.zIndex.modal + 1,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton style={{ borderRadius: 5 }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      }
      dialogProps={{ maxWidth: "md", fullWidth: true }}
    >
      <CollectionDetail collectionId={collectionId} />
    </DialogWrapper>
  );
}

const CollectionDialog = React.forwardRef(render);

export default CollectionDialog;
export interface CollectionDialogRef extends Ref {}
