import { Box, IconButton, makeStyles } from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import React from "react";
import Slick, { Settings } from "react-slick";

const useStyles = makeStyles((theme) => ({
  prevArrow: {
    position: "absolute",
    top: "50%",
    left: `24px !important`,
    zIndex: theme.zIndex.appBar - 1,
  },
  nextArrow: {
    position: "absolute",
    top: "50%",
    right: `24px !important`,
    zIndex: theme.zIndex.appBar - 1,
  },
  root: {
    "& *": {
      outline: "none",
    },
    "& .slick-arrow": {},
    "& .slick-prev": {
      position: "absolute",
      left: `25px !important`,
      zIndex: theme.zIndex.appBar - 1,
    },
    "& .slick-next": {
      position: "absolute",
      right: `25px !important`,
      zIndex: theme.zIndex.appBar - 1,
    },
    "& .slick-dots": {
      bottom: `25px !important`,
      zIndex: theme.zIndex.appBar - 1,
      "& button:before": {
        color: theme.palette.primary,
      },
    },
    "& .slick-track": { display: "flex !important" },
    "& .slick-slide": {
      height: "inherit !important",
      "& > div": { height: "100%" },
    },
  },
}));

const NextArrow = ({ onClick }: any) => {
  const classes = useStyles();
  return (
    <IconButton
      color="primary"
      onClick={onClick}
      className={classes.nextArrow}
      edge="end"
    >
      <NavigateNextIcon />
    </IconButton>
  );
};

const PrevArrow = ({ onClick }: any) => {
  const classes = useStyles();
  return (
    <IconButton
      color="primary"
      onClick={onClick}
      className={classes.prevArrow}
      edge="start"
    >
      <NavigateBeforeIcon />
    </IconButton>
  );
};

interface Props {
  settings?: Settings;
  children?: React.ReactNode;
  count?: number;
}

function render(props: Props, ref: React.RefObject<Slick>) {
  const { children: slides, count = 0, settings = {} } = props;
  const classes = useStyles();

  const slideSettings: Settings = {
    infinite: false,
    ...settings,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  const currentSlidesToShow =
    ref?.current?.props.slidesToShow || settings.slidesToShow;
  const remainder =
    count < currentSlidesToShow ? currentSlidesToShow - count - 1 : 0;
  const padNodes = new Array(remainder).fill(1).map(() => <Box />);

  return (
    <Box className={classes.root}>
      <Slick ref={ref} {...slideSettings}>
        {slides}
        {React.Children.toArray(padNodes)}
      </Slick>
    </Box>
  );
}

const Slider = React.forwardRef(render);
export default Slider;
