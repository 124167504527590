import GridWrapper from "@components/elements/GridWrapper";
import MainFooter from "@components/layouts/main/footers";
import MainHeader from "@components/layouts/main/headers";
import GuestNav from "@components/layouts/main/headers/navs/guest";
import MemberNav from "@components/layouts/main/headers/navs/member";
import { Box, makeStyles } from "@material-ui/core";
import Head from "next/head";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    // backgroundColor: theme.palette.primary.dark,
    // color: theme.palette.getContrastText(theme.palette.primary.dark),
  },
}));

interface Props {
  title: string;
  isAuth?: boolean;
  children?: React.ReactNode;
}

export default function MainLayout(props: Props): JSX.Element {
  const { children, title, isAuth = false } = props;

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Head>
        <title>Best Golf Fitness </title>
      </Head>
      <MainHeader searchField={null}>
        {isAuth && <MemberNav />}
        {!isAuth && <GuestNav />}
      </MainHeader>
      <Box minHeight="85vh" py={2}>
        <GridWrapper spacing={2}>{children}</GridWrapper>
      </Box>
      <MainFooter />
    </Box>
  );
}
