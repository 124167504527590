import GridWrapper from "@components/elements/GridWrapper";
import VideoListItem from "@components/video/listItem";
import { useListVideo } from "@hooks/video/useList";
import { VideoPopulatedDocument } from "@repositories/video/types";
import { QueryOptions } from "@utilities/helpers/queryParser.types";
import React from "react";

interface Props {
  collectionId?: string;
}

export default function VideoList(props: Props): JSX.Element {
  const { collectionId } = props;

  const defaultOptions: QueryOptions = {
    collections: collectionId ? [collectionId] : undefined,
    populated: true,
  };
  const [options] = React.useState<QueryOptions>(defaultOptions);
  const { data: videoList } = useListVideo<VideoPopulatedDocument>(options);

  const listNodes = videoList.map((video) => <VideoListItem video={video} />);

  return (
    <GridWrapper spacing={2}>{React.Children.toArray(listNodes)}</GridWrapper>
  );
}
