import UserProfileAvatar from "@components/userProfile/avatar";
import VideoDialog, { VideoDialogRef } from "@components/video/dialog";
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { VideoPopulatedDocument } from "@repositories/video/types";
import classNames from "classnames";
import moment from "moment";
import React from "react";

const useStyles = makeStyles((theme) => ({
  videoCard: {
    height: "100%",
    // background: theme.palette.primary.main,
    // color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  videoCardHover: {},
}));

interface Props {
  video: VideoPopulatedDocument;
}

export default function VideoRowItem(props: Props): JSX.Element {
  const { video } = props;
  const videoId = video._id;
  const videoName = video.name;
  const videoDescription = video.description;
  const videoCreatedAt = video.createdAt;
  const videoCreator = video.creator;
  const videoCreatorName = videoCreator.name;
  const videoCreatorAvatar = videoCreator.avatar;

  const videoDuration = (video.duration / 60).toFixed(1);
  const thumbUrl = `/api/v1/videos/${videoId}/thumb`;

  const classes = useStyles();

  const videoDialogRef = React.useRef<VideoDialogRef>();
  const [hovering, setHover] = React.useState(false);

  const toggleDialog = () => {
    videoDialogRef.current.open();
  };

  const onMouseEnter = () => {
    setHover(true);
  };

  const onMouseOut = () => {
    setHover(false);
  };

  return (
    <Card
      variant="outlined"
      className={classNames({
        [classes.videoCard]: true,
        [classes.videoCardHover]: hovering,
      })}
    >
      <CardActionArea
        onClick={toggleDialog}
        onMouseEnter={onMouseEnter}
        onMouseOut={onMouseOut}
      >
        <CardMedia
          image={thumbUrl}
          style={{ height: 0, paddingTop: "56.25%", textAlign: "right" }}
        >
          {videoDuration && (
            <Chip
              size="small"
              label={`${videoDuration} mins`}
              style={{
                borderRadius: 5,
                position: "relative",
                bottom: 24 + 8,
                right: 8,
              }}
            />
          )}
        </CardMedia>
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="subtitle1" style={{ maxLines: 2 }}>
              <strong>{videoName}</strong>
            </Typography>
            <Typography variant="caption">
              {moment(videoCreatedAt).fromNow()}
            </Typography>
          </Box>
          <Box
            component="div"
            textOverflow="ellipsis"
            maxHeight="2rem"
            overflow="hidden"
          >
            {videoDescription}
          </Box>
        </CardContent>
        <Divider />
        <CardActions>
          <UserProfileAvatar avatar={videoCreatorAvatar} />
          <Box display="flex" flexDirection="column">
            <Typography variant="subtitle2">{videoCreatorName}</Typography>
            {/* <Typography variant="caption">
              Professional Fitness Coach
            </Typography> */}
          </Box>
        </CardActions>
      </CardActionArea>
      <VideoDialog ref={videoDialogRef} video={video} />
    </Card>
  );
}
