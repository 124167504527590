import PlayListAttachVideo, {
  PlayListAttachVideoRef,
} from "@components/playLists/attachVideo";
import DialogWrapper, { DialogWrapperRef } from "@components/wrappers/dialog";
import { Button } from "@material-ui/core";
import React from "react";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";

interface Ref {
  open: () => void;
}

interface Props {
  videoId: string;
}

function render(props: Props, ref: React.RefObject<Ref>) {
  const { videoId } = props;

  const dialogRef = React.useRef<DialogWrapperRef>();
  const attachRef = React.useRef<PlayListAttachVideoRef>();

  const onClose = () => {
    dialogRef.current.close();
  };

  const onSubmit = () => {
    attachRef.current.submit();
  };

  const dialogActions = (
    <React.Fragment>
      <Button onClick={onClose}>Close</Button>
      <Button variant="contained" color="secondary" onClick={onSubmit}>
        Attach
      </Button>
    </React.Fragment>
  );

  React.useImperativeHandle(ref, () => ({
    open: dialogRef.current.open,
  }));

  return (
    <DialogWrapper
      ref={dialogRef}
      dialogTitle="Attach Video to Play List"
      dialogActions={dialogActions}
      buttonText="Add To Play List"
      buttonProps={{ startIcon: <LibraryAddIcon /> }}
      dialogProps={{ keepMounted: false }}
    >
      <PlayListAttachVideo ref={attachRef} videoId={videoId} />
    </DialogWrapper>
  );
}

const PlayListAttachVideoDialog = React.forwardRef(render);

export default PlayListAttachVideoDialog;
export interface PlayListAttachVideoDialogRef extends Ref {}
