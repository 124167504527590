import VideoDialog from "@components/video/dialog";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { VideoPopulatedDocument } from "@repositories/video/types";
import { getVideoThumbUrl } from "@utilities/web/getVideoThumb";
import React from "react";

const useStyles = makeStyles(() => ({
  root: { display: "flex" },
  media: { width: 200, height: 200 * 0.5625 },
}));

interface Props {
  video: VideoPopulatedDocument;
}

export default function VideoListItem(props: Props): JSX.Element {
  const { video } = props;
  const classes = useStyles();

  const videoId = video._id;
  const videoName = video.name;
  const videoCreator = video.creator;
  const creatorName = videoCreator.name;

  const thumbUrl = getVideoThumbUrl(videoId);

  return (
    <Card variant="outlined" className={classes.root}>
      <CardMedia image={thumbUrl} className={classes.media} />
      <CardContent style={{ flex: 1 }}>
        <Typography component="h5" variant="h5">
          {videoName}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {creatorName}
        </Typography>
      </CardContent>
      <CardActions>
        <VideoDialog video={video} buttonText="View" />
      </CardActions>
    </Card>
  );
}
