import GridWrapper from "@components/elements/GridWrapper";
import PlayListSelectOrCreateField from "@components/playLists/selectOrCreateField";
import React from "react";
import { useForm } from "react-hook-form";

interface FormData {
  videoId: string;
  playListId: string;
}

interface Ref {
  submit: () => void;
}

interface Props {
  data?: FormData;
  onSubmit: (data: FormData) => void;
}

function render(props: Props, ref: React.RefObject<Ref>) {
  const defaultValues: FormData = {
    ...props.data,
  };
  const { handleSubmit, control } = useForm<FormData>({ defaultValues });

  const onSubmit = (data: FormData) => {
    return props.onSubmit(data);
  };

  React.useImperativeHandle(ref, () => ({
    submit: handleSubmit(onSubmit),
  }));

  return (
    <GridWrapper spacing={2}>
      <PlayListSelectOrCreateField
        control={control}
        name="playListId"
        textFieldProps={{
          label: "Play List",
          placeholder: "Select Play List to attach.",
        }}
      />
    </GridWrapper>
  );
}

const PlayListAttachVideoForm = React.forwardRef(render);

export default PlayListAttachVideoForm;
export interface PlayListAttachVideoFormRef extends Ref {}
export interface PlayListAttachVideoFormData extends FormData {}
