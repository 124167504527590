import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";

export interface DialogWrapperRef {
  toggle: () => void;
  close: () => void;
  open: () => void;
}

interface Props {
  hideButton?: boolean;
  buttonText?: string;
  buttonProps?: ButtonProps;
  dialogProps?: Partial<DialogProps>;
  children?: React.ReactNode;
  dialogTitle?: React.ReactNode;
  dialogActions?: React.ReactNode;
  removeDialogContent?: boolean;
  removeDialogTitle?: boolean;
  onOpened?: () => void;
}

function render(
  props: Props,
  ref: React.RefObject<DialogWrapperRef>,
): JSX.Element {
  const {
    hideButton,
    buttonText,
    buttonProps,
    children,
    dialogActions,
    dialogTitle,
    dialogProps = {},
    removeDialogTitle,
    removeDialogContent,
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [dialogOpen, dialogToggle] = React.useState(false);

  const openDialog = () => {
    dialogToggle(true);
    return props.onOpened ? props.onOpened() : null;
  };

  const closeDialog = () => {
    dialogToggle(false);
  };

  const toggleDialog = () => {
    dialogToggle(!dialogOpen);
  };

  React.useImperativeHandle(ref, () => ({
    toggle: toggleDialog,
    close: closeDialog,
    open: openDialog,
  }));

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="sm"
        fullWidth
        open={dialogOpen}
        {...dialogProps}
        onClose={closeDialog}
      >
        {!!dialogTitle && !removeDialogTitle && (
          <DialogTitle>{dialogTitle}</DialogTitle>
        )}
        {!!dialogTitle && !!removeDialogTitle && dialogTitle}
        {!!children && !removeDialogContent && (
          <DialogContent>{children}</DialogContent>
        )}
        {!!children && !!removeDialogContent && children}
        {!!dialogActions && <DialogActions>{dialogActions}</DialogActions>}
      </Dialog>
      {buttonText && !hideButton && (
        <Button {...buttonProps} onClick={toggleDialog}>
          {buttonText}
        </Button>
      )}
    </React.Fragment>
  );
}

const DialogWrapper = React.forwardRef<DialogWrapperRef, Props>(render);

export default DialogWrapper;
