import axios from "axios";

export async function retrieveVideoSourceToken(videoId: string) {
  try {
    const endpoint = `/api/v1/videos/${videoId}/source`;
    const response = await axios.get(endpoint);

    const responseData = response.data;
    return responseData.data?.token;
  } catch {
    return null;
  }
}
