import { BoxProps, Grid, GridProps, GridSpacing } from "@material-ui/core";
import React, { ReactNode } from "react";

interface Props {
  children?: ReactNode;
  spacing?: GridSpacing;
  itemBoxProps?: BoxProps;
  itemSizes?: { [size: string]: number };
  gridProps?: GridProps;
}

export default function GridWrapper(props: Props): JSX.Element {
  const children = props.children;
  const spacing = props.spacing || 0;
  const itemBoxProps = props.itemBoxProps || {};
  const gridProps = props.gridProps || {};
  const itemSizes = props.itemSizes || { xs: 12 };
  return (
    <Grid container spacing={spacing} {...gridProps}>
      {React.Children.map(children, (child) => (
        <Grid item {...itemSizes}>
          {child}
        </Grid>
      ))}
    </Grid>
  );
}
