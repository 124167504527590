import PlayListAttachVideoForm, {
  PlayListAttachVideoFormData,
  PlayListAttachVideoFormRef,
} from "@components/playLists/attachVideoForm";
import { FormHelperText } from "@material-ui/core";
import { attachPlayListVideo } from "@requests/user/playLists/videos/attach";
import React from "react";

interface Ref {
  submit: () => void;
}

interface Props {
  videoId: string;
  onDone?: () => void;
}

function render(props: Props, ref: React.RefObject<Ref>) {
  const { onDone } = props;

  const formData: PlayListAttachVideoFormData = {
    videoId: props.videoId,
    playListId: null,
  };

  const formRef = React.useRef<PlayListAttachVideoFormRef>();
  const [error, setError] = React.useState<string>();

  const onSubmit = (data: PlayListAttachVideoFormData) => {
    const { videoId, playListId } = data;
    attachPlayListVideo(videoId, playListId)
      .then(onDone)
      .catch((err) => {
        setError(err.message);
      });
  };

  React.useImperativeHandle(ref, () => ({
    submit: formRef.current.submit,
  }));

  return (
    <React.Fragment>
      <PlayListAttachVideoForm
        ref={formRef}
        data={formData}
        onSubmit={onSubmit}
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </React.Fragment>
  );
}

const PlayListAttachVideo = React.forwardRef(render);

export default PlayListAttachVideo;
export interface PlayListAttachVideoRef extends Ref {}
