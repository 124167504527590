import {
  UserResource,
  UserResourceMeta,
} from "@repositories/userResource/types";
import { createUserResource } from "@requests/userResources/create";

export const triggerWatchLaterUserResource = (
  resourceId: string,
  resourceType: string,
  watchLater: boolean,
) => {
  const watchLaterMeta: UserResourceMeta = { watchLater };

  const doc: UserResource = {
    type: resourceType,
    resource: resourceId,
    meta: watchLaterMeta,
  };

  return createUserResource(doc);
};
