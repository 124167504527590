import HideOnScroll from "@components/layouts/main/headers/hideOnScroll";
import ScrollToTop from "@components/layouts/scrollToTop";
import {
  AppBar,
  Box,
  ButtonBase,
  Container,
  Drawer,
  Hidden,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Link from "@material-ui/core/Link";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  appbar: {},
  brand: {
    backgroundImage: `url('/assets/logos/bgf_logo.png')`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: theme.spacing(24),
    height: theme.spacing(10),
  },
  brandMobile: {
    backgroundImage: `url('/assets/logos/bgf_logo.png')`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: theme.spacing(18),
    height: theme.spacing(7.5),
  },
  search: {
    // marginLeft: "auto",
    // paddingLeft: theme.spacing(1),
    // paddingRight: theme.spacing(1),
  },
  navButton: { textTransform: "none", marginRight: theme.spacing(2) },
  loginButton: { color: theme.palette.common.white },
  toolbar: {
    height: theme.spacing(10),
    display: "flex",
  },
}));

interface Props {
  searchField?: React.ReactNode;
  children?: React.ReactNode;
}

export default function MainHeader(props: Props): JSX.Element {
  const { children, searchField } = props;

  const [mobileOpen, mobileToggle] = React.useState(false);
  const toggleMobile = () => {
    mobileToggle(!mobileOpen);
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <HideOnScroll>
        <AppBar color="default" elevation={0} className={classes.appbar}>
          <Container>
            <Toolbar className={classes.toolbar}>
              <Hidden smDown>
                <Link href="/">
                  <ButtonBase style={{ marginRight: 32 }}>
                    <Box className={classes.brand}></Box>
                  </ButtonBase>
                </Link>
                {children}
                <Box className={classes.search}>{searchField}</Box>
              </Hidden>
              <Hidden mdUp>
                <Link href="/">
                  <ButtonBase style={{ marginRight: 32 }}>
                    <Box className={classes.brandMobile}></Box>
                  </ButtonBase>
                </Link>
                <IconButton
                  style={{ marginLeft: "auto" }}
                  onClick={toggleMobile}
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>
              <Drawer anchor="right" open={mobileOpen} onClick={toggleMobile}>
                <Toolbar className={classes.toolbar}>Best Golf Fitness</Toolbar>
                <Box
                  // width="25vh"
                  display="flex"
                  flexDirection="column"
                  // justifyContent="center"
                  alignItems="center"
                  mx={1}
                >
                  {children}
                </Box>
              </Drawer>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
      <Toolbar className={classes.toolbar} id="back-to-top-anchor" />
      <ScrollToTop />
    </React.Fragment>
  );
}
