import { UserResource } from "@repositories/userResource/types";
import { minuteUserResource } from "@requests/userResources/minute";

export const triggerMinuteUserResource = (
  videoId: string,
  stoppedAt: number,
  finished: boolean,
) => {
  const doc: UserResource = {
    type: "video",
    resource: videoId,
    meta: { stoppedAt, finished },
  };

  return minuteUserResource(doc);
};
