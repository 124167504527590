import {
  createStyles,
  makeStyles,
  Theme,
  useScrollTrigger,
  Zoom,
} from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.common.white,
      width: theme.spacing(4),
      height: theme.spacing(4),
      position: "fixed",
      display: "flex",
      zIndex: theme.zIndex.appBar,
      justifyContent: "center",
      alignItems: "center",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      borderRadius: theme.spacing(4),
    },
  }),
);

interface Props {}

function ScrollToTop(props: Props) {
  const classes = useStyles();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        <KeyboardArrowUpIcon />
      </div>
    </Zoom>
  );
}

export default ScrollToTop;
